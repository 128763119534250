import { HashRouter  as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./pages/Home";
import Links from "./pages/Links";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' exact element={<Home/>}/>
                <Route path='/links' element={<Links/>}/>
                {/*<Route path='/big_files' element={<BigFiles/>}/>*/}
            </Routes>
        </Router>
    );
}

export default App;
