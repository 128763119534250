import React from 'react';

const IconGear = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_2106_1289)">
                <path d="M18.343 7.5855C18.9682 8.48215 19.4081 9.5176 19.6073 10.6363H22V13.3637H19.6073C19.4081 14.4824 18.9682 15.5178 18.343 16.4145L20.0353 18.1068L18.1068 20.0353L16.4145 18.343C15.5178 18.9682 14.4824 19.4081 13.3637 19.6073V22H10.6363V19.6073C9.5176 19.4081 8.48215 18.9682 7.5855 18.343L5.89315 20.0353L3.96469 18.1068L5.657 16.4145C5.0318 15.5178 4.59186 14.4824 4.39266 13.3637H2V10.6363H4.39266C4.59186 9.5176 5.0318 8.48215 5.657 7.5855L3.96469 5.89315L5.89315 3.96469L7.5855 5.657C8.48215 5.0318 9.5176 4.59186 10.6363 4.39266V2H13.3637V4.39266C14.4824 4.59186 15.5178 5.0318 16.4145 5.657L18.1068 3.96469L20.0353 5.89315L18.343 7.5855Z" fill="#007AFF" stroke="#007AFF" stroke-width="2" stroke-linejoin="round"/>
                <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="white" stroke="white" stroke-width="2" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2106_1289">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconGear;